/* eslint-disable max-len */
import React from 'react';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;
const cyberSafeURL = 'https://www.getcybersafe.gc.ca/en/resources';
const privateSearchEngineURL = 'https://www.domesticshelters.org/articles/technology/safer-searching-online';

export const getTitleIcon = (title, icon) => <div className='grid-x align-middle'>
    <div className='cell shrink margin-right-1'>
        <i className={`fa fa-${icon} fa-2x`}/>
    </div>
    <div className='cell shrink'>
        {title}
    </div>
</div>;

export default [
    {
        title: i18n.t('application.hide_your_tracks.lists.list1.title'),
        description: <div>
            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.lists.list1.items')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/creating_safe_usernames.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.lists.list2.title'),
        description: <div>
            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.lists.list2.items')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/tips_around_use_of_social_media.mp3`}/>
            <br/>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.lists.list2.paragraph', {cyberSafeURL: cyberSafeURL})}}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section1.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section1.paragraph')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/deleting_cookies.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section2.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section2.paragraph')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/clear_your_cache.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section3.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section3.paragraph')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/private_browsing.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section4.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section4.paragraph')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/do_not_track.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section5.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section5.paragraph', {privateSearchEngineURL: privateSearchEngineURL})}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/private_search_engines.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.hide_your_tracks.sections.section6.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.paragraph')}}/>
            <AudioFile path={`/${i18n.locale}/staying_safe_online/in_browser_settings.mp3`}/>
            <br/><br/>
        </div>,
        tabs: [
            {
                title: getTitleIcon('Google chrome', 'chrome'),
                description: <div>
                    <ol>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers1.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers1.items')}}/>
                            <img src={`/hideYourTracks/chrome-private-window.${i18n.locale}.png`}/><br/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/chrome/private_browsing.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers2.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers2.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/chrome/do_not_track.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers3.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers3.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/chrome/clear_your_history.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers4.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers4.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/chrome/clear_cache_and_cookies.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers4.additionalparagraph')}}/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/chrome/additional_options.mp3`}/>
                        </li>
                    </ol>
                </div>
            },
            {
                title: getTitleIcon('Microsoft Edge', 'internet-explorer'),
                description: <div>
                    <ol>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers5.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers5.items')}}/>
                            <img src={`/hideYourTracks/edge-private-window.${i18n.locale}.png`}/><br/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/internet_explorer/in_private_browsing.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers6.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers6.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/internet_explorer/do_not_track.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers7.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers7.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/internet_explorer/clear_your_history.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers8.header')}}/>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers8.item1')}}/>
                            </ul><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/internet_explorer/additional_options.mp3`}/>
                        </li>
                    </ol>
                </div>
            },
            {
                title: getTitleIcon('Safari', 'safari'),
                description: <div>
                    <ol>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers9.header')}}/>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers9.item1')}}/>
                            </ul>
                            <img src={`/hideYourTracks/safari-private-window.${i18n.locale}.png`}/><br/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/safari/private_browsing.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers10.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers10.items')}}/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/safari/do_not_track.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers11.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers11.items')}}/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/safari/clear_your_history.mp3`}/>
                        </li>
                    </ol><br/>
                </div>
            },
            {
                title: getTitleIcon('Mozilla Firefox', 'firefox'),
                description: <div>
                    <ol>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers12.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers12.items')}}/>
                            <img src={`/hideYourTracks/firefox-private-window.${i18n.locale}.png`}/><br/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/firefox/private_browsing.mp3`}/>
                        </li><br/><br/>
                        <li>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers13.header')}}/>
                            <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers13.items')}}/><br/>
                            <AudioFile path={`/${i18n.locale}/staying_safe_online/firefox/do_not_track.mp3`}/>
                        </li><br/><br/>
                        {
                            i18n.locale !== 'fr' && <>
                                <li>
                                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers14.header')}}/>
                                    <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers14.items')}}/><br/>
                                </li><br/><br/>
                                <li>
                                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers15.header')}}/>
                                    <ul dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers15.items')}}/><br/>
                                    <AudioFile path={`/${i18n.locale}/staying_safe_online/firefox/deleting_cookies_and_clearing_cache.mp3`}/>
                                </li>
                            </>
                        }
                        {
                            i18n.locale === 'fr' && <>
                                <li>
                                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers14.header')}}/>
                                    <ul>
                                        <li dangerouslySetInnerHTML={{__html: i18n.t('application.hide_your_tracks.sections.section6.browsers14.item1')}}/>
                                    </ul><br/>
                                    <AudioFile path={`/${i18n.locale}/staying_safe_online/firefox/deleting_cookies_and_clearing_cache.mp3`}/>
                                </li>
                            </>
                        }
                    </ol>
                </div>
            }
        ]
    }
];