import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {assessmentPath, didYouKnowPath, hideYourTracksPath} from '../Routes';
import ContactForm from './ContactForm';
import {Link} from 'react-router-dom';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import AudioFile from '../AudioFile';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const Home = () =>
    <div className='text-center'>
        <img src='/images/logo.png'/>
        <div className='margin-top-1 margin-bottom-2'>
            <h1>{i18n.t('application.home.get_started.header')}</h1>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.get_started.text')}}/>
            <AudioFile path={`/${i18n.locale}/home/get_started.mp3`}/>
            <div className='grid-x align-center-middle margin-top-2'>
                <div className='cell large-5 small-10'>
                    <Link
                        className='expanded button primary shadow button-large'
                        to={assessmentPath}
                    >
                        {i18n.t('application.home.link_to_assessment.text')}
                    </Link>
                </div>
            </div>
        </div>
        <div className='margin-top-3 margin-bottom-2'>
            <h1>{i18n.t('application.home.didYouKnow.title')}</h1>
            <p>{i18n.t('application.home.didYouKnow.description')}</p>
            <p><AudioFile path={`/${i18n.locale}/home/did_you_know.mp3`}/></p>
            <Link className='medium button hollow shadow'
                to={didYouKnowPath}
            >
                {i18n.t('application.home.didYouKnow.title')}
            </Link>
        </div>
        <div className='margin-top-3 margin-bottom-2'>
            <h1>{i18n.t('application.home.stayingSafeOnline.title')}</h1>
            <p>
                {i18n.t('application.home.stayingSafeOnline.description')}
            </p>
            <p><AudioFile path={`/${i18n.locale}/home/staying_safe_online.mp3`}/></p>
            <Link
                className='medium button hollow shadow'
                to={hideYourTracksPath}
            >
                {i18n.t('application.home.stayingSafeOnline.title')}
            </Link>
        </div>
        <div className='margin-top-3 margin-bottom-2'>
            <h1>{i18n.t('application.home.withWomenScreener.title')}</h1>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.withWomenScreener.description')}}/>
            <p><AudioFile path={`/${i18n.locale}/home/with_women_screener.mp3`}/></p>
            <a
                className='medium button hollow shadow'
                href='https://withwomen.ca/'
            >
                {i18n.t('application.home.withWomenScreener.title')}
            </a>
        </div>
        <div className='margin-top-3 margin-bottom-2'>
            <h1>{i18n.t('application.home.aboutUs.title')}</h1>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.aboutUs.description')}}/>
            <AudioFile path={`/${i18n.locale}/home/about_us.mp3`}/>
        </div>
        <div className='margin-top-3 margin-bottom-2'>
            <h1>{i18n.t('application.home.contactUs.title')}</h1>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.home.contactUs.description')}}/>
            <AudioFile path={`/${i18n.locale}/home/contact_us.mp3`}/>
            <ContactForm/>
        </div>
    </div>;

export default connect()(Home);

Home.propTypes = {
    dispatch: PropTypes.func.isRequired
};