import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';
import {quizPath} from '../Routes';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const Assessment = () =>
    <div className='text-center'>
        <h6>{i18n.t('application.assessment.sections.opening_page.header')}</h6>
        <h1>{i18n.t('application.assessment.sections.opening_page.title')}</h1>
        <AudioFile path={`/${i18n.locale}/assessment/assess_prioritize_plan.mp3`}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph1')}}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph2')}}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.opening_page.paragraph3')}}/>
        <div className='grid-x align-center-middle margin-top-3'>
            <div className='cell large-5 small-10'>
                <Link
                    className='expanded button primary shadow'
                    to={quizPath}
                >
                    {i18n.t('application.assessment.sections.opening_page.button')}
                </Link>
            </div>
        </div>
    </div>;

export default connect()(Assessment);

Assessment.propTypes = {
    dispatch: PropTypes.func.isRequired
};