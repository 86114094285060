import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
    actionPlanPath,
    assessmentPath, assessmentResultPath,
    didYouKnowPath,
    hideYourTracksPath, homePath, otherConcernsPath,
    safetyPlanStepsPath,
    setMyConcernsPath,
    emergencyTipsPath
} from './Routes';
import {I18n} from 'i18n-js';
import translations from './../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class LeftMenu extends React.PureComponent {
    menuItems = () => {
        const {assessmentData, match} = this.props;
        const childItems = [];

        if (assessmentData.answers.length !== 0) {
            childItems.push({title: i18n.t('application.left_menu.titles.results'), icon: 'flag', to: assessmentResultPath});
            childItems.push({title: i18n.t('application.left_menu.titles.concerns'), icon: 'bullseye', to: setMyConcernsPath});
        }

        if (assessmentData.myConcernResult.length !== 0) {
            childItems.push({title: i18n.t('application.left_menu.titles.action'), icon: 'tasks', to: actionPlanPath});
            childItems.push({title: i18n.t('application.left_menu.titles.other_concerns'), icon: 'file-text-o', to: otherConcernsPath});
            childItems.push({title: i18n.t('application.left_menu.titles.tips'), icon: 'sticky-note-o', to: safetyPlanStepsPath});
        }

        return [
            {title: i18n.t('application.left_menu.titles.home'), icon: 'home', to: `${match.url}/${homePath}`},
            {title: i18n.t('application.action_plan.sections.plan.subheader'), icon: 'exclamation-triangle', to: `${match.url}/${emergencyTipsPath}`},
            {title: i18n.t('application.left_menu.titles.staying_safe'), icon: 'desktop', to: `${match.url}/${hideYourTracksPath}`},
            {title: i18n.t('application.left_menu.titles.did_you_know'), icon: 'book', to: `${match.url}/${didYouKnowPath}`},
            {title: i18n.t('application.left_menu.titles.assessment'), icon: 'bars', to: `${match.url}/${assessmentPath}`, childItems}
        ];
    };

    render() {
        return <div className='off-canvas position-left' data-off-canvas id='off-canvas'>
            <ul className='menu top-menu-tab'>
                <li className='menu-text'>
                    <img className='logo-image-white' src='/images/logo-white.png'/>
                </li>
            </ul>
            <ul className='vertical menu'>
                {this.menuItems().map(item =>
                    <li key={item.title}>
                        <Link className='grid-x' data-close='off-canvas' to={item.to}>
                            <i className={`fa fa-${item.icon} cell small-2 text-center`}/>
                            <span className='cell'>{item.title}</span>
                        </Link>
                        {
                            item.childItems &&
                            <ul className='nested vertical menu'>
                                {item.childItems.map(childItem =>
                                    <li key={childItem.title}>
                                        <Link className='grid-x' data-close='off-canvas' to={childItem.to}>
                                            <i className={`fa fa-${childItem.icon} cell small-2 text-center`}/>
                                            <span className='cell'>{childItem.title}</span>
                                        </Link>
                                    </li>)}
                            </ul>
                        }
                    </li>)}
            </ul>
        </div>;
    }
}

const mapStateToProps = ({assessmentData}) => ({
    assessmentData
});

export default connect(mapStateToProps)(LeftMenu);

LeftMenu.propTypes = {
    assessmentData: PropTypes.shape({
        answers: PropTypes.array
    }).isRequired,
    dispatch: PropTypes.func.isRequired
};