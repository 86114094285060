import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {push} from 'react-router-redux';
import {notify} from 'react-notify-toast';
import {setPin} from '../../reducers/userData';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {homePath} from '../Routes';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class PinLock extends Component {
    state = {
        code: ''
    };

    setCode = code => {
        if (code.length <= 4) {
            this.setState({code});
        }
    };

    codeClicked = newCode => {
        const {code} = this.state;
        this.setCode(`${code}${newCode}`);
    };

    handleTextChange = event => {
        this.setCode(event.target.value);
    };

    submitPin = () => {
        const {code} = this.state;
        const {dispatch} = this.props;

        if (code.length === 4) {
            dispatch(setPin(code));
            dispatch(push(`/${i18n.locale}/${homePath}`));
        }
        else {
            notify.show(i18n.t('application.pin_lock.error_message'), 'error');
        }
    };

    render() {
        const array = [...Array(10)].map((_, index) => ({id: index === 9 ? 0 : index + 1}));
        const {code} = this.state;
        return (
            <form>
                <div className='text-center'>
                    <h6 dangerouslySetInnerHTML={{__html: i18n.t('application.pin_lock.privacy_header')}}/>
                    <h1 dangerouslySetInnerHTML={{__html: i18n.t('application.pin_lock.pin_header')}}/>
                    <p dangerouslySetInnerHTML={{__html: i18n.t('application.pin_lock.body')}}/>
                    <div className='grid-x grid-padding-y align-center'>
                        <div className='cell medium-2 small-5'>
                            <input
                                className='text-center'
                                maxLength='4'
                                onChange={this.handleTextChange}
                                pattern='[0-9]*' type='number' value={code}
                            />
                        </div>
                    </div>
                    <div className='grid-x align-center'>
                        <div className='shrink cell'>
                            <div className='grid-x align-center small-up-3'>
                                {array.map(value =>
                                    <div className='cell' key={value.id}>
                                        <a
                                            className='hollow button rounded shadow'
                                            onClick={() => this.codeClicked(value.id)}
                                            style={{width: 50, height: 50}}
                                        >
                                            {value.id}
                                        </a>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                    <div className='grid-x align-center-middle'>
                        <div className='cell large-5 small-10'>
                            <a
                                className='expanded button rounded shadow'
                                dangerouslySetInnerHTML={{__html: i18n.t('application.pin_lock.confirm_pin')}}
                                onClick={this.submitPin}
                            />
                            <a
                                className='expanded button clear rounded'
                                dangerouslySetInnerHTML={{__html: i18n.t('application.pin_lock.clear_pin')}}
                                onClick={() => this.setCode('')}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default connect()(PinLock);

PinLock.propTypes = {
    dispatch: PropTypes.func.isRequired
};