import React from 'react';
import PropTypes from 'prop-types';
import AccordionContent from '../AccordionContent';
import HYTContent from '../appContent/HideYourTracks';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const HideYouTracks = ({location: {hash}}) =>
    <div>
        <div className='text-center'>
            <h1>{i18n.t('application.hide_your_tracks.title')}</h1>
        </div>
        <AudioFile path={`/${i18n.locale}/staying_safe_online/intro.mp3`}/>
        {i18n.t('application.hide_your_tracks.paragraphs').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        <AccordionContent content={HYTContent} hash={hash}/>
    </div>;

export default HideYouTracks;

HideYouTracks.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired
};