import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {setMyConcernsPath} from '../Routes';
import {actionPlans} from '../appContent/SDSContent';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const MyConcerns = ({dispatch}) =>
    <div>
        <div className='text-center'>
            <h6>{i18n.t('application.concerns.header')}</h6>
            <h1>{i18n.t('application.concerns.sections.subheader')}</h1>
            <AudioFile path={`/${i18n.locale}/set_my_concerns/intro.mp3`}/>
            <p>
                {i18n.t('application.concerns.sections.text.balance')}
            </p>
            <p dangerouslySetInnerHTML={{__html: i18n.t('application.concerns.sections.text.important')}}/>
            {actionPlans.map(value =>
                <p key={value.name}>
                    <i className={`fa fa-${value.icon} 2x primary-color margin-right-1`}/>
                    <b>{value.name}</b>
                </p>)
            }
            <p>
                {i18n.t('application.concerns.sections.text.slider')}
            </p>
        </div>
        <div className='grid-x align-center-middle margin-top-2'>
            <div className='cell large-5 small-10'>
                <Link className='expanded button primary shadow margin-bottom-2'
                    to={setMyConcernsPath}
                >
                    {i18n.t('application.concerns.sections.text.buttons.set_concerns')}
                </Link>
            </div>
        </div>
    </div>;

const mapStateToProps = ({assessmentData}) => ({
    assessmentData
});

export default connect(mapStateToProps)(MyConcerns);

MyConcerns.propTypes = {
    assessmentData: PropTypes.shape({
        totalQuestions: PropTypes.number,
        yesCount: PropTypes.number,
        myConcernResult: PropTypes.array
    }).isRequired,
    dispatch: PropTypes.func.isRequired
};