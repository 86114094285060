import React from 'react';
import {contactUs} from '../services/contact';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const ContactForm = () => <form
    className='margin-top-2'
    id='contactForm'
    onSubmit={event => {
        event.preventDefault();
        const elements = event.target.elements;
        const name = elements.name.value;
        const email = elements.email.value;
        const subject = elements.subject.value;
        const message = elements.message.value;
        contactUs({name, email, subject, message});
        $('#contactForm')[0].reset();
        notify.show(i18n.t('application.contactForm.emailSent'), 'success');
    }}>
    <div className='grid-y'>
        <div className='grid-x align-justify'>
            <input
                className='cell small-5'
                id='name'
                name='name'
                placeholder={i18n.t('application.contactForm.name')}
                required
                type='text'
            />
            <input
                className='cell small-5'
                id='email'
                name='email'
                placeholder={i18n.t('application.contactForm.email')}
                required
                type='email'
            />
        </div>
        <div className='grid-x'>
            <input
                className='cell'
                id='subject'
                name='subject'
                placeholder={i18n.t('application.contactForm.subject')}
                required
                type='text'
            />
        </div>
        <div className='grid-x'>
            <textarea
                data-validate='true'
                id='message'
                name='message'
                placeholder={i18n.t('application.contactForm.message')}
                required
                rows='5'
            />
        </div>
        <div className='grid-x align-center-middle margin-top-2'>
            <div className='cell large-5 small-10'>
                <button
                    className='expanded button primary shadow'
                    type='submit'
                >
                    {i18n.t('application.contactForm.submit')}
                </button>
            </div>
        </div>
    </div>
</form>;

export default ContactForm;