import React from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';
import HomeLayout from './AppLayout';
import $ from 'jquery';
import connect from 'react-redux/es/connect/connect';
import {replace} from 'react-router-redux';
import {
    actionPlanPath,
    assessmentPath,
    assessmentResultPath, didYouKnowPath,
    emergencyTipsPath,
    actionPlanFinancePath,
    actionPlanHealthPath, homePath,
    actionPlanHousingPath,
    actionPlanLegalPath,
    myConcernsPath,
    myConcernsResultPath,
    safetyPlanPath,
    safetyPlanStepsPath,
    otherConcernsPath,
    setMyConcernsPath,
    actionPlanSocialSupportPath
} from '../Routes';
import LeftMenu from '../LeftMenu';
import ReactGA from 'react-ga4';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

class LayoutWrapper extends React.PureComponent {
    static updateMeta(props) {
        if (props.title.length > 0) {
            document.title = props.title;
            $('meta[name=description]').attr('content', props.description);
            $('meta[property=\'og\\:title\']').attr('content', props.title);
            $('meta[property=\'og\\:description\']').attr('content', props.description);
            $('meta[property=\'og\\:url\']').attr('content', location.href);
        }
    }

    componentDidMount() {
        $(document).on('turbolinks:load', () => setTimeout(this.loadFoundation, 500));
        LayoutWrapper.updateMeta(this.props);
        ReactGA.event('page_view', {page_location: this.props.location.pathname, page_title: this.props.title}); // eslint-disable-line camelcase
    }

    componentDidUpdate() {
        this.loadFoundation();
        this.redirectIfNotAccessible();
        LayoutWrapper.updateMeta(this.props);
        ReactGA.event('page_view', {page_location: this.props.location.pathname, page_title: this.props.title}); // eslint-disable-line camelcase
    }

    loadFoundation = () => {
        $(document).foundation();
        $(document).on('touch tap touchstart', e => {
            if (!$(e.target).hasClass('has-tip') && $('.has-tip').length > 0) {
                $('.has-tip').foundation('hide');
            }
        });
    };

    changeLanguage = locale => {
        const localeRegex = new RegExp(`^/${i18n.locale}?(/|$)`);
        const localePresent = localeRegex.test(location.pathname);
        const newPathname = localePresent ? location.pathname.replace(localeRegex, `/${locale}/`) : `/${locale}${location.pathname}`;
        location.href = `${location.origin}${newPathname}`;
    };

    afterAssessment = path => {
        const afterAssessment = [
            `/${i18n.locale}/${myConcernsPath}`,
            `/${i18n.locale}/${setMyConcernsPath}`,
            `/${i18n.locale}/${assessmentResultPath}`
        ];
        return afterAssessment.indexOf(path) > -1;
    };

    afterMyConcerns = path => {
        const afterMyConcerns = [
            `/${i18n.locale}/${actionPlanPath}`,
            `/${i18n.locale}/${actionPlanFinancePath}`,
            `/${i18n.locale}/${actionPlanHealthPath}`,
            `/${i18n.locale}/${actionPlanHousingPath}`,
            `/${i18n.locale}/${actionPlanLegalPath}`,
            `/${i18n.locale}/${myConcernsResultPath}`,
            // `/${i18n.locale}/${emergencyTipsPath}`,
            `/${i18n.locale}/${safetyPlanPath}`,
            `/${i18n.locale}/${safetyPlanStepsPath}`,
            `/${i18n.locale}/${otherConcernsPath}`,
            `/${i18n.locale}/${actionPlanSocialSupportPath}`
        ];
        return afterMyConcerns.indexOf(path) > -1;
    };

    redirectIfNotAccessible = () => {
        const {dispatch, path, assessmentData} = this.props;
        const isAssessmentDone = assessmentData.answers.length !== 0;
        const isMyConcernsDone = assessmentData.myConcernResult.length !== 0;

        const checkAssessment = this.afterAssessment(path);
        const checkPathMyConcerns = this.afterMyConcerns(path);

        if (!isAssessmentDone && (checkAssessment || checkPathMyConcerns)) {
            dispatch(replace(`/${i18n.locale}/${assessmentPath}`));
        }

        if (!isMyConcernsDone && checkPathMyConcerns) {
            dispatch(replace(`/${i18n.locale}/${setMyConcernsPath}`));
        }
    };

    childAssessmentBar = () => {
        const {
            path
        } = this.props;
        const tabs = [
            {title: i18n.t('application.top_menu.titles.results'), link: `/${i18n.locale}/${assessmentResultPath}`, childPaths: []},
            {title: i18n.t('application.top_menu.titles.concerns'), link: `/${i18n.locale}/${myConcernsResultPath}`, childPaths: []},
            {
                title: i18n.t('application.top_menu.titles.action'), link: `/${i18n.locale}/${actionPlanPath}`, childPaths: [
                    `/${i18n.locale}/${actionPlanLegalPath}`,
                    `/${i18n.locale}/${actionPlanFinancePath}`,
                    `/${i18n.locale}/${actionPlanSocialSupportPath}`,
                    `/${i18n.locale}/${actionPlanHousingPath}`,
                    `/${i18n.locale}/${actionPlanHealthPath}`,
                    `/${i18n.locale}/${emergencyTipsPath}`
                ]
            },
            {
                title: i18n.t('application.top_menu.titles.other_concerns'), link: `/${i18n.locale}/${otherConcernsPath}`, childPaths: [
                    `/${i18n.locale}/${otherConcernsPath}`,
                    // `/${i18n.locale}/${safetyPlanStepsPath}`,
                    `/${i18n.locale}/${safetyPlanPath}`
                ]
            },
            {title: i18n.t('application.top_menu.titles.tips'), link: `/${i18n.locale}/${safetyPlanStepsPath}`, childPaths: []}
        ];
        const selectedIndex = tabs.findIndex(tab => tab.link === path || tab.childPaths.indexOf(path) > -1);
        return selectedIndex < 0 ? null
            : <ul
                className='grid-x tabs child-tab shadow'
                data-active-collapse='true' data-tabs id='collapsing-tabs'>
                {
                    tabs.map((tab, i) =>
                        <li className={`row tabs-title ${selectedIndex === i ? 'is-active' : ''}`}
                            key={tab.title}>
                            <Link aria-selected={selectedIndex === i ? 'true' : 'false'}
                                to={tab.link}
                            >
                                {tab.title}</Link>
                        </li>)
                }
            </ul>;
    };

    topNavBar = () => {
        const {match, path} = this.props;
        const tabs = [
            {title: i18n.t('application.layouts.topNavBar.tabs.menu'), link: '/not-available', childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.home'), link: `${match.url}/${homePath}`, childPaths: ['/lock', '/staying-safe-online', '/']},
            {title: i18n.t('application.layouts.topNavBar.tabs.assessment'), link: `${match.url}/${assessmentPath}`, childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.emergencyTips'), link: `${match.url}/${emergencyTipsPath}`, childPaths: []},
            {title: i18n.t('application.layouts.topNavBar.tabs.didYouKnow'), link: `${match.url}/${didYouKnowPath}`, childPaths: ['/planning', '/support']}];
        tabs.findIndex(tab => tab.link === path || tab.childPaths.indexOf(path) > -1);
        let selectedIndex = tabs.findIndex(tab => tab.link === path || tab.childPaths.indexOf(path) > -1);
        selectedIndex = selectedIndex < 0 ? 2 : selectedIndex;
        return (
            <div data-sticky-container>
                <div className='sticky' data-margin-top='0' data-sticky data-sticky-on='small'>
                    <ul className='grid-x tabs menu top-menu-tab'
                        data-active-collapse='true' id='collapsing-tabs'>
                        {
                            tabs.map((tab, i) =>
                                <li
                                    className={
                                        `${i === 0 ? 'no-border' : 'row'} tabs-title
                                        ${selectedIndex === i ? 'is-active' : ''} 
                                        ${i >= 3 ? 'hide-for-small-only' : ''}`}
                                    key={tab.title}>
                                    {i === 0
                                        ? <a
                                            data-open='off-canvas' style={{padding: '0.95rem 1.25rem'}}>
                                            <i className='fa fa-bars fa-2x'/></a>
                                        : <Link
                                            aria-selected={selectedIndex === i ? 'true' : 'false'}
                                            to={tab.link}
                                        >{tab.title}</Link>
                                    }
                                </li>)
                        }
                        <li className='row tabs-title'>
                            <details>
                                <summary>
                                    <img alt={i18n.t('languages.current')} height={16} src={`/images/languages/${i18n.locale}.png`} width={16}/>
                                    &nbsp;
                                    {i18n.t('languages.current')}
                                </summary>
                                <ul className='sub-menu'>
                                    <li className='text-nowrap' >
                                        <a className='language-flag-wrapper' hrefLang='en' onClick={() => this.changeLanguage('en')} rel='alternate'>
                                            <img alt={translations.en.languages.english} height={16} src='/images/languages/en.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.en.languages.english}</small>
                                        </a>
                                    </li>
                                    <li>
                                        <a className='language-flag-wrapper' hrefLang='es' onClick={() => this.changeLanguage('es')} rel='alternate'>
                                            <img alt={translations.es.languages.spanish} height={16} src='/images/languages/es.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.es.languages.spanish}</small>
                                        </a>
                                    </li>
                                    <li>
                                        <a className='language-flag-wrapper' hrefLang='fr' onClick={() => this.changeLanguage('fr')} rel='alternate'>
                                            <img alt={translations.fr.languages.french} height={16} src='/images/languages/fr.png' width={16}/>
                                            &nbsp;
                                            <small>{translations.fr.languages.french}</small>
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        const {
            component: Component,
            layout: Wrapper,
            path,
            match,
            title,
            ...rest
        } = this.props;

        return (
            <Route
                {...rest}
                render={matchProps =>
                    <Wrapper
                        title={title}
                        {...matchProps}
                    >
                        <React.Fragment>
                            <div className='off-canvas-wrapper'>
                                <LeftMenu match={match}/>
                                <div className='off-canvas-content grid-container' data-off-canvas-content>
                                    {this.topNavBar()}
                                    {this.afterMyConcerns(path) && this.childAssessmentBar()}
                                    <div className='large-8 float-center body-content-container'>
                                        <Component {...matchProps}/>
                                    </div>
                                    <div className='quick-exit'>
                                        <a
                                            className='expanded button exit'
                                            href='https://www.google.com/'>
                                            {
                                                i18n.t('application.layouts.quick_exit')
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Wrapper>
                }
            />
        );
    }
}

const mapStateToProps = ({assessmentData}) => ({
    assessmentData
});

export default connect(mapStateToProps)(LayoutWrapper);

LayoutWrapper.propTypes = {
    assessmentData: PropTypes.shape({
        answers: PropTypes.array
    }).isRequired,
    component: PropTypes.any,
    dispatch: PropTypes.func.isRequired,
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

LayoutWrapper.defaultProps = {
    component: null,
    layout: HomeLayout
};