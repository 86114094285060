import React from 'react';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {emergencyTipsPath} from '../Routes';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const dangerBottom = <div>
    <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.danger_bottom.header')}}/>
    <ul>
        {i18n.t('application.assessment.sections.results.danger_bottom.list_items').map((item, index) =>
            <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
    </ul>
</div>;

const extremeDanger = {
    title: i18n.t('application.assessment.sections.results.extreme_danger.header'),
    topDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/extreme_danger.mp3`}/>
        {i18n.t('application.assessment.sections.results.extreme_danger.paragraphs').map((paragraph, index) =>
            <p dangerouslySetInnerHTML={{__html: paragraph}} key={index}/>)}
    </>,
    bottomDescription: <>
        {dangerBottom}
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.extreme_danger.bottom_description', {emergencyTipsPath: emergencyTipsPath})}}/>
    </>
};

const severeDanger = {
    title: i18n.t('application.assessment.sections.results.severe_danger.header'),
    topDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/severe_danger_prior_to_next_button.mp3`}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.severe_danger.text.score_suggestion')}}/>
        {
            i18n.locale === 'fr' && <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.severe_danger.text.description')}}/>
        }
    </>,
    bottomDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/severe_danger_under_next_button.mp3`}/>
        {dangerBottom}
    </>
};

const increasedDanger = {
    title: i18n.t('application.assessment.sections.results.increased_danger.header'),
    topDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/increased_danger_prior_to_next_button.mp3`}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.score_suggestion')}}/>
    </>,
    bottomDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/increased_danger_under_next_button.mp3`}/>
        <div className='grid-x'>
            <div className='cell'>
                {
                    i18n.locale === 'fr' && <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.description')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.subheader')}}/>
                    </>
                }
                <ul>
                    {i18n.t('application.assessment.sections.results.increased_danger.text.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>

                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.increased_danger.text.bottom_description')}}/>
            </div>
        </div>
    </>
};

const variableDanger = {
    title: i18n.t('application.assessment.sections.results.variable_danger.header'),
    topDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/variable_danger_prior_to_next_button.mp3`}/>
        <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.score_suggestion')}}/>
    </>,
    bottomDescription: <>
        <AudioFile path={`/${i18n.locale}/assessment/results/variable_danger_under_next_button.mp3`}/>
        <div className='grid-x'>
            <div className='cell'>
                {
                    i18n.locale === 'fr' && <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.description')}}/>
                }
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.subheader')}}/>
                <ul>
                    {i18n.t('application.assessment.sections.results.variable_danger.text.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.assessment.sections.results.variable_danger.text.bottom_description')}}/>
            </div>
        </div>
    </>
};

export const extremeDangerScore = 18;

export const severeDangerScore = 14;

export const increasedDangerScore = 8;

export const assessmentResult = score => {
    if (score >= extremeDangerScore) {
        return extremeDanger;
    }
    else if (score >= severeDangerScore) {
        return severeDanger;
    }
    else if (score >= increasedDangerScore) {
        return increasedDanger;
    }

    return variableDanger;
};