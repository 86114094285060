import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AccordionContent from '../AccordionContent';
import safetyPlanContent, {
    mountsinaiSafetyURL,
    pcawaSafetyURL
} from '../appContent/SafetyPlan';
import {safetyPlanStepsPath} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import {Link} from 'react-router-dom';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const SafetyPlan = ({location: {hash}, dispatch, safetyPlanStepIds}) => {
    const filteredContent = safetyPlanContent().filter(value =>
        value.linkedIds
            ? value.linkedIds.some(r => safetyPlanStepIds.indexOf(r) >= 0)
            : value);
    filteredContent.map(value => {
        if (value.tabs) {
            value.tabs = value.tabs.filter(tab => !tab.linkedId || safetyPlanStepIds.indexOf(tab.linkedId) > -1);
        }

        return value;
    });

    return (
        <div>
            {filteredContent[0] && <div className='text-center'>
                <h6>{i18n.t('application.safety.sections.plan.subheader')}</h6>
                <h1>{filteredContent[0].title}</h1>
            </div>}

            <AccordionContent content={filteredContent} hash={hash}/>
            <footer>
                {i18n.t('application.safety.sections.footer.header')}
                <ul>
                    {i18n.t('application.safety.sections.footer.resource_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.footer.caption')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('application.safety.sections.footer.extra')}}/>
                <AudioFile path={`/${i18n.locale}/safety_plan_steps/end_text_under_each_tab.mp3`}/>
            </footer>
            <div className='grid-x align-center-middle margin-top-2'>
                <div className='cell large-5 small-10'>
                    <Link className='expanded button clear'
                        to={safetyPlanStepsPath}
                    >
                        {i18n.t('application.safety.sections.tips.back_button')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({assessmentData: {safetyPlanStepIds}}) => ({
    safetyPlanStepIds
});

export default connect(mapStateToProps)(SafetyPlan);

SafetyPlan.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
    safetyPlanStepIds: PropTypes.arrayOf(PropTypes.number).isRequired
};