import React from 'react';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export default [
    {
        title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.title'),
        description: <p><AudioFile path={`/${i18n.locale}/did_you_know/unsafe_relationships.mp3`}/></p>,
        tabs: [
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.money.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.money.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/taking_your_money.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.condoms.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.condoms.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/refusing_to_use_condoms.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.control.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.control.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/controlling_who_you_speak_with.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.blame.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.blame.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/blaming_and_causing_guilt.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.stalking.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.stalking.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/sending_constant_call_messages.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.lying.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.lying.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/Lying_denying_minimizing.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.sex.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.sex.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/having_sex_when_unwilling.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.violence.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.violence.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/pushing_or_grabbing_you.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.criticism.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.criticism.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/name_calling_criticizing.mp3`}/>
                </div>
            },
            {
                title: i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.financial_abuse.title'),
                description: <div>
                    <p>{i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.accordions.financial_abuse.description')}</p>
                    <AudioFile path={`/${i18n.locale}/did_you_know/stopping_you_from_working.mp3`}/>
                </div>
            }
        ],
        bottomDescription: <>
            {i18n.t('application.didYouKnow.accordions.type_of_unsafe_relationships.paragraphs').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
            <AudioFile path={`/${i18n.locale}/did_you_know/unsafe-relationships-footer.mp3`}/>
        </>
    },
    {
        title: i18n.t('application.didYouKnow.accordions.unsafe_relationship_start_slowly.title'),
        description: <div>
            <p>{i18n.t('application.didYouKnow.accordions.unsafe_relationship_start_slowly.description')}</p>
            <AudioFile path={`/${i18n.locale}/did_you_know/even_a_nice_person.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.didYouKnow.accordions.social_support.title'),
        description: <div>
            <p>{i18n.t('application.didYouKnow.accordions.social_support.description')}</p>
            <ol>
                {i18n.t('application.didYouKnow.accordions.social_support.example_list').map((example, index) => <li key={index}>{example}</li>)}
            </ol>
            <AudioFile path={`/${i18n.locale}/did_you_know/social_support_make_you_feel_less_alone.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.didYouKnow.accordions.unsafe_behaviours.title'),
        description: <div>
            <p>{i18n.t('application.didYouKnow.accordions.unsafe_behaviours.description')}</p>
            <AudioFile path={`/${i18n.locale}/did_you_know/you_are_not_responsible.mp3`}/>
        </div>
    },
    {
        title: i18n.t('application.didYouKnow.accordions.safety_steps.title'),
        description: <div>
            <p>{i18n.t('application.didYouKnow.accordions.safety_steps.description')}</p>
            <AudioFile path={`/${i18n.locale}/did_you_know/anyone_experiencing_unsafe_relationship.mp3`}/>
        </div>
    }
];