/* eslint-disable max-len */
import React from 'react';
import {
    actionPlanFinancePath,
    actionPlanHealthPath,
    actionPlanHousingPath,
    actionPlanLegalPath,
    safetyPlanPath,
    actionPlanSocialSupportPath
} from '../Routes';
import AudioFile from '../AudioFile';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const schliferclinicURL = 'https://www.schliferclinic.com/legal-services/';
const oasisfemmesURL = 'https://www.oasisfemmes.org/services';
const legalAidURL = 'https://www.legalaid.on.ca';
const legalClinicsURL = 'https://www.legalaid.on.ca/legal-clinics';
const northYorkURL = 'https://nywc.org/';
const rexdaleCentreURL = 'http://www.rexdalewomen.org/';
const workingwomenURL = 'http://www.workingwomencc.org/location/fairview/programs-services/programs-services/' +
    'womens-support-services/';
const consentedURL = 'https://www.healthline.com/health/sexual-coercion#common-scenarios';
const benefitFinderToolURL = 'https://www.toronto.ca/community-people/employment-social-support/benefit-finder-tool/#0';
const appleTreeURL = 'https://appletreemedicalgroup.com/';
const stalkingLogURL = 'http://publications.gov.sk.ca/documents/9/95776-Stalking Log 16-11-1.pdf';
const actionPlanContent = () => ({
    [`/${i18n.locale}/${actionPlanHousingPath}`]: {
        title: i18n.t('app_content.action_plan.sections.housing.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.housing.top_concern'),
        topDescription: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing.top_description.paragraph1')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/housing/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.emergency_housing.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.shelters')}}/>
                <table className='margin-bottom-1'>
                    <tbody>
                        <tr>
                            <td>
                                <p className='font-bold margin-bottom-0'
                                    dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.shelter_safe')}}/>
                            </td>
                            <td>
                                <ul>
                                    {i18n.t('app_content.action_plan.sections.emergency_housing.body.list_items').map((item, index) =>
                                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className='font-bold margin-bottom-0'
                                    dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.intake_line')}}/>
                            </td>
                            <td>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emergency_housing.body.near_toronto')}}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <AudioFile path={`/${i18n.locale}/action_plans/housing/find_out_about_emergency_housing.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.new_home.title'),
            description: <div>
                {
                    i18n.locale !== 'fr' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.housing_options')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.toronto_list_items').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.special_program')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.program_list_items').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        {
                            i18n.locale !== 'es' && <>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.transitional_housing')}}/>
                                <ul>
                                    {i18n.t('app_content.action_plan.sections.new_home.body.transitional_list_items').map((item, index) =>
                                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                                </ul>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.looking_for_places')}}/>
                            </>
                        }
                    </>
                }
                {
                    i18n.locale === 'fr' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.housing_options_for_low_income')}}/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.toronto')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.toronto_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.ontario')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.new_home.body.ontario_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/housing/find_a_new_home.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.housing_rights.title'),
            description: <div>
                {i18n.t('app_content.action_plan.sections.housing_rights.body.paragraphs').map((item, index) =>
                    <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                <p>
                    <b dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.housing_rights.body.visit_website')}}/>
                </p>
                <AudioFile path={`/${i18n.locale}/action_plans/housing/learn_about_your_housing_rights.mp3`}/>
            </div>
        }].filter(item => item !== false),
        bottomDescription:
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.new_home.body.bottom_description')}}/>
    },
    [`/${i18n.locale}/${actionPlanFinancePath}`]: {
        title: i18n.t('app_content.action_plan.sections.finances.title'),
        topMyConcernText: '',
        topDescription: <div><p
            dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.finances.top_description.top_of_list')}}/>
        <AudioFile path={`/${i18n.locale}/action_plans/finances/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.hide_money.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.hide_money.body.save_money')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.hide_money.body.list_items').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/finances/hide_away_some_money.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.social_services.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_services.body.programs')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.social_services.body.programs_list', {benefitFinderToolURL: benefitFinderToolURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/finances/sign_up_for_social_services.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.financial_literacy.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.places_in_toronto')}}/>
                { i18n.locale === 'en' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.ywca')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.ywca_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.micro_lending')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.micro_lending_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                {i18n.locale === 'es' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.settlement_program')}}/>
                        <AudioFile path={`/${i18n.locale}/action_plans/finances/get_help_with_financial_literacy_intro.mp3`}/>
                        <br/><br/>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.employability_program')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.employability_program_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <AudioFile path={`/${i18n.locale}/action_plans/finances/employability_program.mp3`}/>
                        <br/><br/>
                    </>
                }
                {
                    i18n.locale !== 'fr' &&
                    <><p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.financial_empowerment')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.financial_empowerment_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                {
                    i18n.locale === 'fr' &&
                    <>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.financial_literacy.body.helping_women_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.support_job_search')}}/>
                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/finances/get_help_with_financial_literacy.mp3`}/>
            </div>
        },
        i18n.locale === 'es' &&
        {
            title: i18n.t('app_content.action_plan.sections.open_personal_account.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.open_personal_account.body.understand_banking_needs')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/finances/banking_needs.mp3`}/>
            </div>
        }].filter(item => item !== false),
        bottomDescription: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.financial_literacy.body.questions')}}/>
            <p dangerouslySetInnerHTML={{
                __html: i18n.t('app_content.action_plan.sections.financial_literacy.body.finances',
                    {
                        housingPath: `/${i18n.locale}/${actionPlanHousingPath}`,
                        safetyPlanPath: `/${i18n.locale}/${safetyPlanPath}`
                    })
            }}/>
        </div>
    },
    [`/${i18n.locale}/${actionPlanHealthPath}`]: {
        title: i18n.t('app_content.action_plan.sections.health.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.health.top_concern'),
        topDescription: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.health.body.see_below')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/health/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.community_health_centre.title'),
            description: <div>
                {i18n.t('app_content.action_plan.sections.community_health_centre.body.paragraphs').map((item, index) =>
                    <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                <ul>
                    {i18n.t('app_content.action_plan.sections.community_health_centre.body.healthcare_workers_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/community_health_centre.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.family_doctor.title'),
            description: <div>
                <ul>
                    {i18n.t('app_content.action_plan.sections.family_doctor.body.virtual_list', {appleTreeURL: appleTreeURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/family_doctor.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.emotional_health.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health.body.dealing_with_ex')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.emotional_health.body.dealing_with_ex_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                {
                    i18n.locale === 'fr' &&
                    <>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.emotional_health.body.professional_health_resources')}}/>
                        <ul>
                            {i18n.t('app_content.action_plan.sections.emotional_health.body.professional_health_resources_list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/health/emotional_health.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.sexual_health.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.sexual_health.body.without_consent')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.sexual_health.body.consent_list', {consentedURL: consentedURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/sexual_health.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.who_to_call.title'),
            description: <div>
                <p><b
                    dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.who_to_call.body.emergencies')}}/>
                </p>
                <ul>
                    {i18n.t('app_content.action_plan.sections.who_to_call.body.emergencies_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/health/health_and_mental_health_emergencies.mp3`}/>
            </div>
        }],
        bottomDescription:
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.who_to_call.body.bottom_description')}}/>
    },
    [`/${i18n.locale}/${actionPlanSocialSupportPath}`]: {
        title: i18n.t('app_content.action_plan.sections.social_support.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.social_support.body.top_concern'),
        topDescription: <div>
            <p
                dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_support.body.support_system')}}/>
            <AudioFile path={`/${i18n.locale}/action_plans/social_support/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.someone_you_trust.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.someone_you_trust.body.support')}}/>
                {
                    i18n.locale !== 'fr' && <ul>
                        {i18n.t('app_content.action_plan.sections.someone_you_trust.body.support_list').map((item, index) =>
                            <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </ul>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_someone_you_trust.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.support_group.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.support_group.body.meeting_others')}}/>
                <ul>
                    <li>
                        <div
                            dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.support_group.body.may_find_helpful')}}/>
                        {i18n.locale !== 'es' && i18n.t('app_content.action_plan.sections.support_group.body.places', {
                            northYorkURL: northYorkURL,
                            rexdaleCentreURL: rexdaleCentreURL
                        }).map((item, index) =>
                            <div dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </li>
                </ul>
                <ul>
                    {i18n.t('app_content.action_plan.sections.support_group.body.call_or_visit').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_a_peer.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.womens_counselor.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.womens_counselor.body.goals')}}/>
                <ul>
                    <li>
                        <div
                            dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.womens_counselor.body.counselors')}}/>
                        <br/>
                        {i18n.t('app_content.action_plan.sections.womens_counselor.body.counselors_list').map((item, index) =>
                            <p dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </li>
                </ul>
                <ul>
                    {i18n.t('app_content.action_plan.sections.womens_counselor.body.connect_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/talk_to_a_violence_counselor.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.social_activities.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_activities.body.feeling_alone')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.social_activities.body.activities', {workingwomenURL: workingwomenURL}).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/social_support/try_out_social_activities.mp3`}/>
            </div>
        }],
        bottomDescription:
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.social_activities.body.bottom_description')}}/>
    },
    [`/${i18n.locale}/${actionPlanLegalPath}`]: {
        title: i18n.t('app_content.action_plan.sections.legal.title'),
        topMyConcernText: i18n.t('app_content.action_plan.sections.legal.body.top_concern'),
        topDescription: <div><p
            dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal.body.steps')}}/>
        <AudioFile path={`/${i18n.locale}/action_plans/legal/intro.mp3`}/>
        </div>,
        tabs: [{
            title: i18n.t('app_content.action_plan.sections.family_court_support.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_court_support.body.support_workers')}}/>
                <ul>
                    <li>
                        {i18n.t('app_content.action_plan.sections.family_court_support.body.toronto.caption')}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.family_court_support.body.toronto.program_list', {
                                schliferclinicURL: schliferclinicURL,
                                oasisfemmesURL: oasisfemmesURL
                            }).map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                    <li dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.family_court_support.body.outside_toronto.caption')}}/>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/sign_up_for_the_family_court.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.legal_clinic.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.legal_clinic.body.legal_advice')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.legal_clinic.body.resources_list', {
                        legalAidURL: legalAidURL,
                        legalClinicsURL: legalClinicsURL
                    }).map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/contact_a_community_legal_clinic.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.gather_evidence.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.gather_evidence.body.keep_records')}}/>
                <ul>
                    <li>
                        {i18n.t('app_content.action_plan.sections.gather_evidence.body.records.caption', {stalkingLogURL: stalkingLogURL})}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.gather_evidence.body.records.list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                    <li>
                        {i18n.t('app_content.action_plan.sections.gather_evidence.body.notes.caption')}
                        <ul>
                            {i18n.t('app_content.action_plan.sections.gather_evidence.body.notes.list').map((item, index) =>
                                <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                        </ul>
                    </li>
                </ul>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/gather_evidence.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.access_a_lawyer.title'),
            description: <table>
                <tbody>
                    <tr>
                        <td className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.free_legal_advice.caption')}</td>
                        <td>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.free_legal_advice.description')}}/>
                            <AudioFile path={`/${i18n.locale}/action_plans/legal/free_legal_advice.mp3`}/>
                        </td>
                    </tr>
                    { i18n.locale !== 'es' &&
                        <tr>
                            <td className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.referral_service.caption')}</td>
                            <td>
                                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.referral_service.description')}}/>
                                <AudioFile path={`/${i18n.locale}/action_plans/legal/use_the_law_society_referral_service.mp3`}/>
                            </td>
                        </tr>}

                    <tr>
                        <td className='font-bold'>{i18n.t('app_content.action_plan.sections.access_a_lawyer.body.duty_counsels.caption')}</td>
                        <td>
                            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.access_a_lawyer.body.duty_counsels.description')}}/>
                            <AudioFile path={`/${i18n.locale}/action_plans/legal/learn_how_to_access_a_lawyer.mp3`}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        }, {
            title: i18n.t('app_content.action_plan.sections.protection_orders.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.protection_orders.body.limit_contact')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.protection_orders.body.two_types')}}/>
                <ol>
                    {i18n.t('app_content.action_plan.sections.protection_orders.body.types_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ol>
                {
                    i18n.locale !== 'fr' && <ul>
                        {i18n.t('app_content.action_plan.sections.protection_orders.body.orders_and_bonds_list').map((item, index) =>
                            <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                    </ul>
                }
                <AudioFile path={`/${i18n.locale}/action_plans/legal/consider_a_protection_plan.mp3`}/>
            </div>
        }, {
            title: i18n.t('app_content.action_plan.sections.immigration_status.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.concerns')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.things_to_consider')}}/>
                <ul>
                    {i18n.t('app_content.action_plan.sections.immigration_status.body.consideration_list').map((item, index) =>
                        <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
                </ul>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.family_campaign')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.more_information')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/address_concern_about_immigration_status.mp3`}/>
            </div>
        },
        i18n.locale === 'es' && {
            title: i18n.t('app_content.action_plan.sections.about_child_custody.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.about_child_custody.body.Rights')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.about_child_custody.body.data_website')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.about_child_custody.body.custody')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.about_child_custody.body.child_protection')}}/>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.about_child_custody.body.further_guidance')}}/>
            </div>
        },
        i18n.locale === 'fr' && {
            title: i18n.t('app_content.action_plan.sections.guide_on_family_law.title'),
            description: <div>
                <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.guide_on_family_law.body.description')}}/>
                <AudioFile path={`/${i18n.locale}/action_plans/legal/reference_guide.mp3`}/>
            </div>
        }
        ].filter(item => item !== false),
        bottomDescription:
            <p dangerouslySetInnerHTML={{__html: i18n.t('app_content.action_plan.sections.immigration_status.body.bottom_description')}}/>
    }
});

export default actionPlanContent;