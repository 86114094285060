import React from 'react';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';
import AudioFile from '../AudioFile';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const emergencyTipsContent = () => [
    {
        title: i18n.t('emergency.sections.app_content.escape_plan.title'),
        description: <div>
            <p dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.escape_plan.header')}}/>
            <ul>
                <li dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.escape_plan.list_header')}}/>
                <li dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.escape_plan.body_documents')}}/>
                <AudioFile path={`/${i18n.locale}/emergency_tips/emergency_escape_plan_part_1.mp3`}/>
                <li dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.escape_plan.body_wallet.list_items')}}/>
            </ul>
            <p dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.escape_plan.body_wallet.paragraphs')}}/>
            <AudioFile path={`/${i18n.locale}/emergency_tips/emergency_escape_plan_part_2.mp3`}/>
        </div>
    },
    {
        title: i18n.t('emergency.sections.app_content.call_911.title'),
        description: <div>
            <ul>
                {i18n.t('emergency.sections.app_content.call_911.list_items').map((item, index) =>
                    <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
            </ul>
            <p dangerouslySetInnerHTML={{__html: i18n.t('emergency.sections.app_content.call_911.footer')}}/>
            <AudioFile path={`/${i18n.locale}/emergency_tips/what_to_expect_if_you_call_911.mp3`}/>
        </div>
    },
    {
        title: i18n.t('emergency.sections.app_content.violent_incident.title'),
        description: <div>
            <ul>
                {i18n.t('emergency.sections.app_content.violent_incident.list_items').map((item, index) =>
                    <li dangerouslySetInnerHTML={{__html: item}} key={index}/>)}
            </ul>
            <AudioFile path={`/${i18n.locale}/emergency_tips/during_a_violent_incident.mp3`}/>
        </div>
    }
];

export default emergencyTipsContent;