import React from 'react';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

export const EmergencyAction = () =>
    <div className='text-center'>
        <div>
            <h6>{i18n.t('application.assessment.emergencyAction.headers.1')}</h6>
            <h1>{i18n.t('application.assessment.emergencyAction.headers.2')}</h1>
            <p>
                {i18n.t('application.assessment.emergencyAction.description')}
            </p>
        </div>
        <div className='margin-top-3'>
            <h1>Headers Copy</h1>
            <p>
                Body copy. Not sure what go here. Can flesh this out
                more when we have the content.
            </p>
        </div>
    </div>;