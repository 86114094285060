import {actionPlanFinancePath, actionPlanHealthPath, actionPlanHousingPath, actionPlanLegalPath, actionPlanSocialSupportPath} from '../Routes';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

// ---------- Get action plans and priority items Start ----------

export const actionPlans = [
    {name: i18n.t('application.appContent.SDSContent.actionPlans.socialSupport'), icon: 'users'},
    {name: i18n.t('application.appContent.SDSContent.actionPlans.finance'), icon: 'dollar'},
    {name: i18n.t('application.appContent.SDSContent.actionPlans.health'), icon: 'heartbeat'},
    {name: i18n.t('application.appContent.SDSContent.actionPlans.housing'), icon: 'home'},
    {name: i18n.t('application.appContent.SDSContent.actionPlans.legal'), icon: 'gavel'}
];

// -- NOTE: It should be linked to id instead of names, and it will effect Action Plans component and detail apge.
export const actionPlanDetailPath = name => {
    switch (name) {
    case actionPlans[0].name:
        return actionPlanSocialSupportPath;
    case actionPlans[1].name:
        return actionPlanFinancePath;
    case actionPlans[2].name:
        return actionPlanHealthPath;
    case actionPlans[3].name:
        return actionPlanHousingPath;
    case actionPlans[4].name:
        return actionPlanLegalPath;
    default:
        return '';
    }
};

export const myConcernItems = [];

for (let i = 0; i < actionPlans.length - 1; i++) {
    for (let j = i + 1; j < actionPlans.length; j++) {
        myConcernItems.push({one: actionPlans[i], two: actionPlans[j]});
    }
}