import React from 'react';
import DYKContent from '../appContent/DoYouKnow';
import AccordionContent from '../AccordionContent';
import {I18n} from 'i18n-js';
import translations from './../../locales.json';

const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang;

const DidYouKnow = () =>
    <div>
        <div className='text-center'>
            <h1>{i18n.t('application.didYouKnow.title')}</h1>
        </div>
        <AccordionContent content={DYKContent}/>
    </div>;

export default DidYouKnow;